<template>
    <el-dialog
        :title="$t('cars.car_details')"
        :visible.sync="visible"
        width="700px"
        top="40px"
        :close-on-click-modal="false"
        :before-close="closeModal"
    >
        <el-form v-loading="$wait.is('loading.car_details')" :model="carData" class="px-16">
            <el-form-item :label="$t('cars.name')" prop="name">
                <el-input v-model="carData.name" disabled />
            </el-form-item>
            <el-form-item :label="$t('cars.license_plate')" prop="license_plate">
                <el-input v-model="carData.license_plate" disabled />
            </el-form-item>
            <el-form-item :label="$t('cars.odometer')" prop="odometer">
                <el-input v-model.number="carData.odometer" disabled />
            </el-form-item>
            <el-form-item :label="$t('cars.production_year')" prop="production_year">
                <el-input v-model.number="carData.production_year" disabled />
            </el-form-item>
            <el-form-item :label="$t('cars.holder')" prop="holder">
                <el-input v-model="carData.holder" disabled />
            </el-form-item>
            <el-form-item :label="$t('cars.note')" prop="note">
                <el-input v-model="carData.note" type="textarea" :rows="6" resize="none" disabled />
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },

        selectedCarUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            carData: {},
        };
    },

    watch: {
        visible() {
            this.getCarDetails();
        },
    },

    methods: {
        async getCarDetails() {
            this.$wait.start('loading.car_details');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/assets/cars/${this.selectedCarUuid}`);
            this.carData = data;
            this.carData.holder = data.holder.name ? `${data.holder.name} ${data.holder.surname}` : '';
            this.$wait.end('loading.car_details');
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
